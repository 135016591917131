@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.r {
  border: solid red 3px;
}

.g {
  border: solid green 3px;
}

.b {
  border: solid blue 3px;
}
header {
  font-size: 120%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./image/background.jpg");
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: repeat-y;
  background-color: black;
}

.header-text {
  position: absolute;
  top: 50%;
  max-width: 1140px;
  animation: fadeInRight;
  animation-duration: 1s;
  font-family: Raleway;
  font-weight: 700;
  font-size: 40px;
}
.different_color {
  color: steelblue;
}

.container {
  display: flex;
  justify-content: center;
  min-height: 3rem;
}
.width {
  max-width: 70vw;
}

.width20rem {
  max-width: 40rem;
}

.width2 {
  width: 70vw;
}

.about {
  color: sandybrown;
  min-height: 20rem;
}

.skills {
  color: sandybrown;
  min-height: 30rem;
}

.project {
  color: sandybrown;
  min-height: 10rem;
}

.title {
  font-weight: 600;
  color: sandybrown;
  height: 3rem;
}
.text {
  text-align: center;
  color: aliceblue;
  font-size: large;
}

.height1 {
  min-height: 4rem;
}

.container2 {
  display: flex;
  justify-content: center;
  min-height: 3rem;
}

.sample_project_start {
  color: sandybrown;
  min-height: 10rem;
}

img {
  max-width: 100%;
  max-height: 30rem;
  display: block;
  object-fit: cover;
}

.samle_project {
  width: 100%;
  min-height: 40rem;
}

.height100 {
  height: 100%;
}

.width100 {
  width: 100%;
}

.spacer5rem {
  min-height: 5rem;
}
.sandybrown {
  color: sandybrown;
}

.height3rem {
  min-height: 3rem;
}

.height6rem {
  min-height: 3rem;
}

.imgeHeight {
  height: 2rem;
  display: inline;
  margin-right: 0.5rem;
}
